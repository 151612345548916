import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DataTable from 'react-data-table-component';
import { ColorRing } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../apis/url_constants';
import { deleteProductRequest, fetchProductRequest, multipleDeleteProductRequest } from '../../redux_store/actions/productActions';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse'; // Import papaparse for CSV file parsing

const Product = () => {
  const [showAlert, setShowAlert] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [csvData, setCsvData] = useState(null); // State for storing parsed CSV data
  const dispatch = useDispatch();

  const { product, loading, error } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(fetchProductRequest());
  }, [dispatch]);

  const handleMulitpleDelete = () => {
    const confirmAlert = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => {
          const selectedIds = selectedRows.map(row => row.id);
          dispatch(multipleDeleteProductRequest(selectedIds));
          setSelectedRows([]);
          hideAlert();
        }}
        onCancel={hideAlert}
      >
        This action cannot be undone.
      </SweetAlert>
    );

    setShowAlert(confirmAlert);
  };

  const handleDelete = (row) => {
    const confirmAlert = (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={() => handleConfirmDelete(row)}
        onCancel={hideAlert}
      >
        This action cannot be undone.
      </SweetAlert>
    );

    setShowAlert(confirmAlert);
  };

  const handleConfirmDelete = (row) => {
    dispatch(deleteProductRequest(row.id));
    hideAlert();
  };

  const hideAlert = () => {
    setShowAlert(null);
  };

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const formatDate = (datetimeString) => {
    const date = new Date(datetimeString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const columns = product && product.length > 0 ? Object.keys(product[0]).map((key) => {
    if (key === 'image') {
      return {
        name: 'Image',
        cell: (row) => {
          const filePath = row[key];

          if (typeof filePath !== 'string') {
            return <div>No Image/Video Available</div>;
          }

          const fileExtension = filePath.split('.').pop().toLowerCase();

          const isImage = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'].includes(fileExtension);
          const isVideo = ['mp4', 'mov', 'avi', 'mkv'].includes(fileExtension);

          return filePath ? (
            isImage ? (
              <img
                src={`${BASE_URL}${filePath}?${new Date().getTime()}`}
                alt="Product Image"
                width="100"
                height="100"
                style={{ objectFit: 'cover' }}
              />
            ) : isVideo ? (
              <video
                src={`${BASE_URL}${filePath}?${new Date().getTime()}`}
                autoPlay
                width="100"
                height="100"
                style={{ objectFit: 'cover' }}
              >
                Your browser does not support the video tag.
              </video>
            ) : (
              <div>No valid file format</div> // Fallback for unsupported file format
            )
          ) : (
            <div>No Image/Video Available</div> // Fallback if filePath is missing or falsy
          );
        },
        sortable: false,
      };
    }

    if (key === 'createdAt') {
      return {
        name: 'Created At',
        selector: (row) => formatDate(row[key]),
        sortable: true,
      };
    }

    if (key === 'status') {
      return {
        name: key.charAt(0).toUpperCase() + key.slice(1),
        cell: (row) => (
          <input type='checkbox'
            checked={row[key]}
            className='form-check-input'
            disabled
          />
        ),
        sortable: true,
      };
    }

    return {
      name: key.charAt(0).toUpperCase() + key.slice(1),
      selector: (row) => row[key],
      sortable: true,
    };
  }) : [];

  const columnsToRemove = ['Id', 'Description', 'ShortDescription', 'BannerImages', 'HasOptions', 'ProductCategories', 'ProductSubCategories', 'Variants', 'Options', 'RelatedProducts', 'BasePrice', 'ProductTags', 'Discount', 'FreeShipping', 'DiscountAmount', 'ProductTaxes', 'OutOfStock', 'InStock', 'SizeChart', 'Reviews', 'Deals', 'Title', 'FinalPrice', 'DiscountPercentage', 'RoundedBasePrice', 'GuaranteeWarranty', 'EnableTaxes', 'Slug', 'MetaDescription', 'MetaKeywords', 'Images', 'BuyNow', 'AddToCart', 'Wishlist', 'ProductFaqs', 'Enquiry', 'Whatsapp', 'HasFAQs', 'LastModified'];

  const filteredColumns = columns.filter((column) => !columnsToRemove.includes(column.name));

  const actionsColumn = {
    name: 'Actions',
    cell: (row) => (
      <>
        <i onClick={() => handleDelete(row)} className='ti ti-trash text-danger fs-6 px-1'></i>
        <Link to={`/addProduct/${row.id}`}><i className='ti ti-pencil text-info fs-6 px-1'></i></Link>
        <Link to={`/addProduct/View/${row.id}`}><i className='ti ti-eye text-primary fs-6 px-1'></i></Link>
        <Link to={`/variant/${row.id}`}><i className='ti ti-apps text-secondary fs-6 px-1'></i></Link>
      </>
    ),
    sortable: false,
  };

  const modifiedColumns = [actionsColumn, ...filteredColumns];

  // Filter the products based on the search term
  const filteredProducts = product.filter(p =>
    Object.values(p).some(value =>
      String(value).toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Handle CSV File Upload
  const handleCSVUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          console.log('CSV Data:', result.data); // Log the parsed data
          setCsvData(result.data); // Store parsed data in state
          // You can now handle the parsed data (e.g., add products to the database)
        },
        header: true, // Treat the first row as the header
      });
    }
  };

  return (
    <>
      <div className='col-12'>
        <div className="card">
          <div className='card-header d-flex justify-content-between align-items-center'>
            <h3>Products</h3>
            <CSVLink
              data={filteredProducts}
              filename="products.csv"
              className="btn btn-success ms-2"
            >
              Export CSV
            </CSVLink>
            <div className='d-flex align-items-center'>
              <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="form-control"
              />
              {selectedRows.length > 0 && (
                <button onClick={handleMulitpleDelete} className='btn btn-danger'>Delete Selected</button>
              )}
              <a href={`${BASE_URL}/Products`} target='_blank' className='btn btn-dark ms-2 btn-sm'>Preview</a>
              <Link to="/addProduct" className='btn btn-primary btn-sm ms-2'>Add Content</Link>

              {/* CSV Upload Button */}
              {/* <input
                                type="file"
                                accept=".csv"
                                onChange={handleCSVUpload}
                                className="btn btn-info ms-2"
                            /> */}
            </div>
          </div>
          <div className='card-body'>
            {loading && <ColorRing />}
            {error && <div className="alert alert-danger">Error: {error}</div>}
            <DataTable
              pagination
              columns={modifiedColumns}
              data={filteredProducts}
              selectableRows
              selectableRowsHighlight
              onSelectedRowsChange={handleRowSelected}
              selectedRows={selectedRows}
              progressPending={loading}
              progressComponent={<ColorRing />}
            />
            {showAlert}
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
